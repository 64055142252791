<template>
	<div class="main" ref="main">
		<el-row class="main-center-text">
			<el-row class="main-center">
				<el-col class="main-right-text" :span="19">
					<!-- 筛选组件 -->
					<el-row v-show="!show_qrcode" style=" overflow: hidden;border-radius: 5px;box-shadow: 0px 0px 6px #c4c4c4;">
						<el-row :gutter="20" style="padding:10px; border-bottom:1px dashed #eeeeee; background: white;height: 146px">
							<el-row>
								<el-col :span="4">
									<p style="font-size: 14px; font-weight: bold; line-height: 40px; height: 40px; display: block; text-indent: 32px">我的工程</p>
								</el-col>
								<el-col :span="5" :push="15">
									<p style="font-size: 14px; color: #CCCCCC; line-height: 40px; height: 40px; display: block;">（总空间{{session_total_storage/1024}}G/已用{{session_use_storage}}M)</p>
								</el-col>
							</el-row>
							<el-row style="line-height: 73px">
								<el-col :span="15">
									<el-radio-group v-model="fiter_type_radio_group" @change="fiter_type_radio_group_change()" style="margin-left: 32px;">
										<el-radio :label="null" size="mini">全部</el-radio>
										<el-radio :label="1" size="mini">模型</el-radio>
										<el-radio :label="2" size="mini">动画</el-radio>
										<el-radio :label="3" size="mini">脚本</el-radio>
									</el-radio-group>
								</el-col>
								<!-- 搜索框 -->
								<el-col :span="8">
									<el-input placeholder="请输入内容" v-model="input_search" class="input-with-select" @keyup.enter.native="btn_click_search()"
									 style="width:380px;height:  42px;">
									</el-input>
								</el-col>
							</el-row>
						</el-row>
					</el-row>
					<!-- 列表 -->
					<el-row style="width: 1100px;">
						<!-- 下载二维码 -->
						<el-row class="weima">
							<el-row class="MyShowCode-navbar" :span="24">
								<el-col :span="4">
									<span @click="download_all" v-show="show_qrcode">全部下载</span>
								</el-col>
								<el-col :span="4" :offset="15">
									<el-button @click="click_upload()" size="mini" style="background: transparent; border:1px solid #c43e1b; color:#c43e1b; ">
										添加资源
									</el-button>
									<el-button @click="click_qrcode()" size="mini" style="background: transparent; border:1px solid #c43e1b;color:#c43e1b; ">
										显示二维码
									</el-button>
								</el-col>

							</el-row>
						</el-row>
					</el-row>
					<!--  -->
					<div class="main-right-text-img" v-show="!show_qrcode">
						<!-- 资源列表 -->
						<div class="uploadingimgtwo" ref="uploadingimgtwo" v-for="(item, index) in resource_list" :key="item.id">
							<!-- 转移文件夹 -->
							<section style="display: flex;width: 100%; justify-content: space-between;">
								<!-- <span class="icon icon2" @click="click_transfer(item)"></span> -->
								<!-- <span class="icon" @click="click_delete_item(item)"></span> -->
								<div style="display: flex; margin-left: 240px; margin-top: 10px;">
									<div class="item" v-show="item.is_code==0" style="display: flex;margin-right: 7px;" @click="click_show_qrcode(item, index,'生成二维码',)">
										<img src="../../assets/img/erweima01.svg" alt title="生成二维码" style="display: block; width: 17px; height: 17px;" />
									</div>
									<div class="item" v-show="item.is_code!=0" style="display: flex;" @click="click_show_qrcode(item, index,'查看二维码')">
										<img src="../../assets/img/erweima.svg" class="erweima_img" title="查看二维码" style="display: block; width: 17px; height: 17px;" />
									</div>
								</div>
							</section>
							<!-- 图片 -->
							<div style="height: 205px;overflow: hidden;">
								<div class="imgbox">
									<img @click="ResourceDetails(item)" oncontextmenu="return false;" :src="common.retun_img(item.thumbnail)" alt
									 style="width: 100%;margin: 0 auto; display: block; " />
								</div>
							</div>
							<!-- name+id -->
							<p style="display:flex; justify-content: space-between; margin-right: 20px; border-bottom: 1px solid #CCCCCC;">
								<span class="textoverfloat" style="font-size: 14px; color: #000000; margin-bottom: 5px; text-align: left;">{{ item.name }}</span>
								<span style="font-size: 12px;">ID:{{ item.resource_id }}</span>
							</p>
							<div class="up-bott-font">
								<span class="goldcoin">金币:{{item.goldcoin?item.goldcoin:'0'}}</span>
								<span class="goldcoin">
									{{
                  (item.file_size / 1024 /1024).toFixed(1)
                  }}MB
								</span>

								<div class="showoperation">
									<el-tooltip class="item" effect="dark" content="修改" placement="top">
										<!-- <img src="../../assets/img/上架.png" alt=""> -->
										<b style="font-size: 12px;display: flex;width: 50px;" @click="click_modify(item)" v-show="item.type!=1">
											<img src="../../assets/img/修改.png" alt style="display: block; width: 14px; height: 14px;" />
											<span class="text">修改</span>
										</b>
									</el-tooltip>
									<div style="display: flex;justify-content: space-between;">
										<span style=" cursor: pointer; position: relative;">
											<div class="QRcodeclass" :ref="item.id" style="display: none;">
												<vue-qr :logoSrc="
										                require('../../assets/img/erweimalogo.png')
										            "
												 :text="qrcode_url" :size="200"></vue-qr>
											</div>
											
										</span>
										<el-tooltip class="item" effect="dark" :content="item.state==1?'下架此资源':'上架此资源'" placement="top">
											<!-- <img src="../../assets/img/上架.png" alt=""> -->
											<b style="font-size: 12px; display: flex; margin-left: 5px;" @click="click_upload_cloud(item)" v-show="item.type!=1">
												<img :src="item.state==1?imgurl:imgurltow" alt style="display: block; width: 20px; height: 20px;" />
												<span class="text">{{item.state==1?'下架':'上架'}}</span>
											</b>
										</el-tooltip>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 分页 -->
					<el-pagination background layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size="pagesize"
					 :current-page="currentPage" :total="allpage" v-show="show_qrcode_text=='二维码'&&allpage>=15" style="margin-left: calc(40%);margin-top: 20px;"></el-pagination>
				</el-col>
			</el-row>
		</el-row>
		<!-- 转移弹出框 -->
		<el-dialog title="转移文件" :visible.sync="dialogVisible_transfer" width="30%">
			<el-select placeholder="请选择转移的文件夹" v-model="dialogVisible_transfer_value">
				<el-option v-for="item in dialogVisible_transfer_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible_transfer = false">取 消</el-button>
				<el-button type="primary" @click="dialog_btn_click_transfer()">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 添加二维码弹窗 -->
		<el-dialog title="添加二维码" :visible.sync="dialogVisibles_add_qrcode" width="30%">
			<p>{{viptext}}</p>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisibles_add_qrcode = false">取 消</el-button>
				<el-button type="primary" @click="addqr()">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 申请上架请求 -->
		<el-dialog title="申请上架" :visible.sync="dialogVisibles_upload" width="30%">
			<p style="line-height: 40px; text-indent: 40px;">定价说明：10金币=¥1元，建议定价范围0-1000金币</p>
			<el-row>
				<el-col :span="16">
					<el-form label-width="110px">
						<el-form-item label="上架定价">
							<el-input v-model="UploadConfirmation_text" maxlength="4"></el-input>
						</el-form-item>
					</el-form>
				</el-col>
				<el-col :span="8">
					<p style="line-height: 40px;">单位金币</p>
				</el-col>
			</el-row>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisibles_upload = false">取 消</el-button>
				<el-button type="primary" @click="UploadConfirmation()">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="选择资源" :visible.sync="ExhibitionAllResources" width="78%">

		</el-dialog>
	</div>
</template>
<script>
	//请求数据
	import {
		//转移文件夹
		account_resource_transfer,
		//添加二维码
		account_code_add,
		//申请上架
		resource_state_examine,
		//下架资源
		resource_state_lower,
		//用户信息
		requestStorage,
		requestaccount_account_list,
		requestResource_purchase,
		resource_purchase,
		requestdirectory_add,
		account_project_add_list,
		requestaccount_resource_list,
		account_project_delete,
		others_code_add,
	} from "../../api/request.js";

	import MyShowRightProjectCode from "@/views/MyShow/MyShowRightProjectCode.vue";
	//二维码生成插件
	import vueQr from "vue-qr";
	export default {
		data() {
			return {
				/**
	 * 	file_size: 7
		id: 1
		name: "测试(1)"
		resource_id: 15407
		state: 2
		thumbnail: "C2110C1720FAEAE6E9729869FACB41DB.png
		*/
				//资源列表
				resource_list: [],
				//全部资源，我的工程+我的收藏
				Resource_list_one: [],
				Resource_list_tow: [],
				//分页总条数
				allpage: 0,
				//每页展示几条数据
				pagesize: null,
				//转移弹窗
				dialogVisible_transfer: false,
				//转移文件夹选择
				dialogVisible_transfer_options: [],
				//转移文件夹选中值
				dialogVisible_transfer_value: "",
				//添加二维码
				dialogVisibles_add_qrcode: false,
				//申请上架
				dialogVisibles_upload: false,
				//搜索框里的搜索值
				input_search: "",
				//筛选动画/模型
				fiter_type_radio_group: null,
				//资源状态 全部/已上架/未上架
				fiter_status_radio_group: 3,

				//是否显示二维码列表
				show_qrcode: false,
				//二维码文字
				show_qrcode_text: "二维码",
				//二维码地址show_qrcode_text
				qrcode_url: "",
				//选中的资源
				selected_item: null,
				//父组件-MyShow
				parent: null,
				//申请上架定价金额
				UploadConfirmation_text: "",
				//
				showup: true,
				//生成二维码的资源
				showitem: null,
				//需要上传的资源
				pricedata: "",
				//当前页面
				currentPage: 1,
				//二维码列表
				QRdata: [],
				//定价金币
				form: {
					name: "",
				},
				//判断是否有数据
				Notyettext: null,
				//下架图标
				imgurl: require("../../assets/img/下架.png"),
				//上架图标
				imgurltow: require("../../assets/img/上架.png"),
				//传给后台的二维码地址
				rquerqrcode_url: null,
				//添加二维码弹出框文字
				viptext: "此章节暂无二维码，是否添加二维码，添加二维码则扣除300金币",
				//判断二维码是否生成过
				bimface_id: "",
				//父组件判断搜索框
				parent_search: false,
				qrdata: null,
				session_total_storage: null,
				session_use_storage: null,
				//所以资源内容弹出框
				ExhibitionAllResources: false,
				levl2_options_list: null,
				levl2_options_value: null,
				add_ProjectList_input: null,
				pagesize_tow: 0,
				currentPage_tow: 0,
				allpage_tow: 0,
				pagesize_one: 0,
				currentPage_one: 0,
				allpage_one: 0,
				value: '',
				input_search_dialog: ''
			};
		},
		components: {
			//二维码显示组件
			MyShowRightProjectCode,
			//二维码生成插件
			vueQr,
		},
		methods: {
			//点击修改
			click_modify(item) {

				this.ExhibitionAllResources = false;
				let parent = this.$parent.$parent.$parent.$parent;
				parent.$refs.ModeUpload.mounteds(item.resource_id);
				parent.$refs.MyShowLeft.initupload();
				let id = item.resource_id;
				this.$router.push({
					path: "/MyShow",
					query: {
						id,
						ids: 4,
						level_0: 3,
					},
				});
				parent.set_current_select_sequence(4);

				// let id = item.resource_id
				// this.$router.push({
				// 	path: "/ModeUpload",
				// 	query: {
				// 		id
				// 	}
				// })
			},
			btn_click_upload() {
				this.ExhibitionAllResources = false;
				let parent = this.$parent.$parent.$parent.$parent;
				parent.set_current_select_sequence(4);
				parent.$refs.MyShowLeft.initupload();
			},
			btn_click_search_dialog() {
				console.log(this.value)
				this.click_upload(true, this.value)
			},
			click_checked(e) {
				// console.log(this.$refs.uploadingimgs)
				console.log(e);
			},
			click_upload() {
				let id;
				console.log(this.parent.$refs.MyShowLeft.current_select_level2_item)
				if(this.parent.$refs.MyShowLeft.current_select_level2_item==null){
					id='null'
				}else{
					id=this.parent.$refs.MyShowLeft.current_select_level2_item.id;
				}
				this.$router.push({
						path: "ResourceAllocationDirectory",
						query: {
							id:id,
						},
					})
			},
			//初始化
			init: function(_parent) {
				this.parent = _parent;
			},
			//资源跳转详情
			ResourceDetails(item) {
				if (item.state == 1) {
					let id = item.resource_id;
					this.$router.push({
						path: "/ModeResources",
						query: {
							id,
						},
					});
				} else {
					this.$message("此资源还未上架，请先上架");
				}
			},
			//重置
			reset: function() {
				this.fiter_status_radio_group = null;
				this.fiter_type_radio_group = null;
			},
			//设置数据
			refresh_data_project: function(data, per_page, current_page, total) {
				console.log(data);
				if (data.data.length == 0) {
					this.Notyettext = true;
				} else {
					this.Notyettext = false;
				}
				this.resource_list = data.data;
				this.allpage = data.total;
				this.currentPage = data.current_page;
				this.pagesize = data.per_page;
			},
			//点击搜索按钮
			btn_click_search: function() {
				this.parent_search = true;
				
				this.parent.$refs.MyShowLeft.request_current_resource_list(
					null,
					null,
					null,
					true,
					true,
					this.input_search
				);
			},
			//全部 上架 未上架
			fiter_status_radio_group_change: function(v) {
				if (this.fiter_status_radio_group == "2") {
					this.showup = false;
				} else {
					this.showup = true;
				}
				//刷新当前目录的数据
				this.parent.$refs.MyShowLeft.request_current_resource_list(
					this.fiter_status_radio_group,
					this.fiter_type_radio_group,
					1,
					true,
					true
				);
			},
			//全部 模型 动画
			fiter_type_radio_group_change: function(v) {
				//刷新当前目录的数据
				this.parent.$refs.MyShowLeft.request_current_resource_list(
					this.fiter_status_radio_group,
					this.fiter_type_radio_group
				);
			},
			//全部下载
			download_all: function() {
				this.$refs.MyShowRightProjectCode.downloadFile_all();
			},
			//点击切换资源和二维码界面
			click_qrcode: function() {
			this.parent.current_select_sequence=6;
			},
			//点击二维码显示或生成处理函数
			click_show_qrcode(item, index, text, name) {
				console.log(item);
				if (item.type == 1) {
					let data = {
						resource_id: item.resource_id,
					};
					requestResource_purchase(data).then((res) => {
						if (res.ret == "资源没有购买!") {
							this.$message({
								message: "请先购买此资源",
								type: "warning",
							});
						}
						return;
					});
				}
				this.viptext =
					"此章节暂无二维码，是否添加二维码，添加二维码则扣除300金币";
				this.bimface_id = item.bimface_id;
				this.showitem = item;
				let datae = window.btoa(item.resource_id);
				this.qrcode_url = location.origin + "#/QRCodeModeResources?id=" + datae;
				this.rquerqrcode_url =
					location.origin + "%23/QRCodeModeResources?id=" + datae;
				this.qrdata = {
					resource_id: item.resource_id,
					name: item.name,
					qrcode_url: this.rquerqrcode_url,
				};
				let data = {
					resource_id: item.resource_id,
				};
				if (item.is_code > 0) {
					this.viptext =
						"您已修改此资源，二维码需要重新生成，再次生成二维码需扣除300金币";
				}
				let _this = this;
				if (text == "生成二维码") {
					for (let i = 0; i < _this.resource_list.length; i++) {
						this.$refs[_this.resource_list[i].id][0].style.display = "none";
					}
					this.dialogVisibles_add_qrcode = true;
					return;
				}
				if (this.bimface_id == item.bimface_id) {
					if (this.$refs[item.id][0].style.display == "block") {
						this.$refs[item.id][0].style.display = "none";
					} else {
						for (let i = 0; i < _this.resource_list.length; i++) {
							this.$refs[_this.resource_list[i].id][0].style.display = "none";
						}
						this.$refs[item.id][0].style.display = "block";
					}
				} else {
					for (let i = 0; i < _this.resource_list.length; i++) {
						this.$refs[_this.resource_list[i].id][0].style.display = "none";
					}

					this.dialogVisibles_add_qrcode = true;
				}
			},
			//Pagination 分页 currentPage 改变
			handleCurrentChange(val) {
				window.scrollTo(0, 0);
				this.parent.$refs.MyShowLeft.request_current_resource_list(
					null,
					null,
					val,
					true
				);
			},
			//点击确定按钮（添加对话框）
			dialog_btn_click_add() {
				let _this = this;
				if (
					this.add_ProjectList_input.length == 0 ||
					this.add_ProjectList_input.indexOf(" ") >= 0
				) {
					this.$message({
						message: "请填写项目名称",
						type: "warning",
					});
					return;
				}
				//获取最大的sequence
				let max_sequence = 0;
				for (let h = 0; h < this.levl2_options_list.length; h++) {
					if (this.levl2_options_list[h].sequence > max_sequence) {
						max_sequence = this.levl2_options_list[h].sequence;
					}
					if (this.levl2_options_list[h].name == this.add_ProjectList_input) {
						this.$message({
							message: "不可添加重复文件夹名称",
							type: "warning",
						});
						return;
					}
				}
				let sequence = max_sequence + 1;
				let addData = {
					name: this.add_ProjectList_input,
					parent_id: _this.parent.$refs.MyShowLeft.projectList[0].id,
					level: 2,
					sequence: sequence,
					parent_node_id: _this.parent.$refs.MyShowLeft.projectList[0].node_id,
				};
				//添加请求

				requestdirectory_add(addData).then((res) => {
					if (res.code == 0) {
						_this.parent.$refs.MyShowLeft.request_level2_list();
						this.add_ProjectList_input = "";
						_this.$message({
							message: "成功添加文件夹",
							type: "success",
						});
					}
				});
			},
			//添加二维码
			addqr() {
				this.dialogVisibles_add_qrcode = false;
				let item = this.showitem;
				let data = this.qrdata;

				if (item.type == 1) {
					others_code_add(data).then((res) => {
						if (res.code == 0) {
							this.$refs.MyShowRightProjectCode.UpQRData();
							this.$refs.MyShowRightProjectCode.UpQRData_tow();
							let Deducting_gold_coins = {
								goldcoin: 30,
							};
							this.parent.$refs.MyShowLeft.request_current_resource_list(
								null,
								null,
								null,
								true
							);
							this.$message({
								message: "成功添加二维码",
								type: "success",
							});

							if (res.ret == null) {
								if (this.$refs[item.id][0].style.display == "block") {
									this.$refs[item.id][0].style.display = "none";
								} else {
									this.$refs[item.id][0].style.display = "block";
								}
							} else {
								if (this.$refs[item.id][0].style.display == "block") {
									this.$refs[item.id][0].style.display = "none";
								} else {
									this.$refs[item.id][0].style.display = "block";
								}
							}
						}
					});
				} else {
					account_code_add(data).then((res) => {
						if (res.code == 0) {
							this.$refs.MyShowRightProjectCode.UpQRData();
							let Deducting_gold_coins = {
								goldcoin: 30,
							};
							this.parent.$refs.MyShowLeft.request_current_resource_list(
								null,
								null,
								null,
								true
							);
							this.$message({
								message: "成功添加二维码",
								type: "success",
							});
							if (res.ret == null) {
								if (this.$refs[item.id][0].style.display == "block") {
									this.$refs[item.id][0].style.display = "none";
								} else {
									this.$refs[item.id][0].style.display = "block";
								}
							} else {
								if (this.$refs[item.id][0].style.display == "block") {
									this.$refs[item.id][0].style.display = "none";
								} else {
									this.$refs[item.id][0].style.display = "block";
								}
							}
						}
					});
				}
			},
			//删除我的工程里的资源
			click_delete_item(item) {
				let _this = this;
				this.$confirm(
						"此操作将在本项目中移除不在显示，不会删除原始文件，如需需要请重新选择添加，是否继续",
						"提示", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "warning",
						}
					)
					.then(() => {
						let data = {
							id: item.id,
						};
						account_project_delete(data).then((res) => {
							if (res.code == 0) {
								this.$message({
									type: "success",
									message: "删除成功!",
								});
								//刷新当前目录的数据
								_this.parent.$refs.MyShowLeft.request_current_resource_list(
									null,
									null,
									null,
									true
								);
								//刷新目标目录的数据
								let item = _this.MyShow.get_level_two_item_by_nodeId(
									_this.parent.$refs.MyShowLeft.projectList[0].node_id
								);
								if (null == item) {
									console.log(
										"invalid nodeId:" +
										_this.parent.$refs.MyShowLeft.projectList[0].id
									);
									return;
								}
								_this.parent.$refs.MyShowLeft.request_resource_list_other_directory(
									item,
									true
								);
								_this.parent.$refs.MyShowLeft.OpenByDefault2(
									_this.parent.$refs.MyShowLeft.projectList[0]
								);
							}
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						});
					});
			},



			//改变目录
			click_transfer(item) {
				this.dialogVisible_transfer = true;
				this.selected_item = item;
				let data = {
					parent_id: 1,
				};
				let _this = this;

				this.dialogVisible_transfer_options = [];
				let level2_list = this.parent.$refs.MyShowLeft.get_level2_list();
				//二级列表赋值
				for (let i = 0; i < level2_list.length; i++) {
					_this.dialogVisible_transfer_options.push({
						value: level2_list[i].node_id,
						label: level2_list[i].name,
					});
				}
			},
			//点击确定按钮（改变目录）
			dialog_btn_click_transfer() {
				console.log(this.selected_item);
				if (this.dialogVisible_transfer_value == "") {
					this.$message({
						message: "请选择文件夹",
						type: "warning",
					});
					return;
				}
				this.dialogVisible_transfer = false;
				let _this = this;

				let data1 = {
					resource_id: this.selected_item.id,
				};
				requestResource_purchase(data1).then(function(e) {
					if (e.code == 0) {
						let data = {
							transfer_nodeid: this.dialogVisible_transfer_value,
							value_array: [this.selected_item.id],
						};
						account_resource_transfer(data).then((res) => {
							if (res.code == 0) {
								this.$message({
									message: "转移成功",
									type: "success",
								});
								//刷新目标目录的数据
								let item = _this.MyShow.get_level_two_item_by_nodeId(
									_this.dialogVisible_transfer_value
								);
								if (null == item) {
									console.log(
										"invalid nodeId:" + _this.dialogVisible_transfer_value
									);
									return;
								}
								_this.parent.$refs.MyShowLeft.request_resource_list_other_directory(
									item,
									true
								);
								//刷新当前目录的数据
								_this.parent.$refs.MyShowLeft.request_current_resource_list(
									null,
									null,
									null,
									true
								);
							}
						});
					}
				});
			},
			//点击上传弹出框处理函数
			UploadConfirmation() {
				if (this.UploadConfirmation_text == "") {
					this.$message({
						message: "定价不能为空",
						type: "warning",
					});
					return;
				}
				let _this = this;
				this.dialogVisibles_upload = false;

				let data = {
					goldcoin: parseInt(this.UploadConfirmation_text),
					resource_id: this.pricedata.resource_id,
				};
				resource_state_examine(data).then((res) => {
					if (res.code == 0) {
						this.$message({
							message: "申请成功",
							type: "success",
						});
						//刷新目标目录的数据
						let item = _this.MyShow.get_level_two_item_by_nodeId(
							_this.parent.$refs.MyShowLeft.projectList[0].node_id
						);
						if (null == item) {
							console.log(
								"invalid nodeId:" +
								_this.parent.$refs.MyShowLeft.projectList[0].node_id
							);
							return;
						}

						_this.parent.$refs.MyShowLeft.request_resource_list_other_directory(
							item,
							true
						);
						//刷新当前目录的数据
						_this.parent.$refs.MyShowLeft.request_current_resource_list(
							null,
							null,
							null,
							true
						);
					}
				});
			},
			//点击上传处理函数
			click_upload_cloud(item) {
				this.UploadConfirmation_text = "";
				if (item.goldcoin) {
					this.form.name = item.goldcoin;
				} else {
					this.form.name = "";
				}
				this.pricedata = item;
				let _this = this;

				if (this.pricedata.state == 1) {
					this.$confirm("是否下架此资源, 是否继续?", "提示", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "warning",
						})
						.then(() => {
							let data = {
								resource_id: item.resource_id,
							};
							resource_state_lower(data).then((res) => {
								_this.$message({
									message: "下架成功",
									type: "success",
								});
							});
						})
						.catch(() => {
							this.$message({
								type: "info",
								message: "已取消下架",
							});
						});
				} else if (this.pricedata.state == 2 || this.pricedata.state == 0) {
					this.dialogVisibles_upload = true;
				} else {
					this.$message({
						message: "此资源还未上架",
						type: "warning",
					});
				}
			},
		},
		mounted() {
			if(JSON.parse(localStorage.getItem('storage'))==null){
				let data = {};
				requestStorage(data).then((res) => {
					console.log(res);
					localStorage.setItem('storage',JSON.stringify(res.ret))
					this.session_total_storage = res.ret.total_storage;
					this.session_use_storage = res.ret.use_storage;
				});
			}else{
				this.session_total_storage = JSON.parse(localStorage.getItem('storage')).total_storage;
				this.session_use_storage = JSON.parse(localStorage.getItem('storage')).use_storage;
			}

		},
	};
</script>
<style scoped>
	@import "../../style/common.css";

	.main {
		padding-top: 0px;
	}

	.main-center-text {
		/* background: #f8f8f8; */
	}

	.main-center {
		width: 1100px;
		background-color: #f8f8f8;
		display: flex;
		justify-content: flex-start;
		margin: 0 auto;
	}

	.main-right-text {
		width: 1100px;
		background-color: #f8f8f8;
	}

	.icon {
		width: 20px;
		background: url(../../assets/img/close.png) no-repeat center center;
		display: block;
		height: 15px;
		cursor: pointer;
	}

	.icon2 {
		background: url(../../assets/img/转移.png) no-repeat center center;
	}

	.MyShowCode-navbar {
		height: 40px;
		line-height: 40px;
		display: flex;
		justify-content: flex-start;
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.MyShowCode-navbar {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
	}

	.MyShowCode-navbar span {
		margin-left: 13px;
		margin-right: 13px;
	}

	.MyShowCode-navbar span b {
		margin-left: 15px;
	}

	.weima {
		height: 51px;
		line-height: 51px;
		justify-content: flex-end;
		margin-top: 5px;
	}

	.weima span {
		color: #000000;
		font-size: 14px;
		margin-right: 10px;
		cursor: pointer;
	}

	.main-right-text-img {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	.uploadingimg {
		width: 264px;
		background-color: #fff;
		align-items: center;
		justify-content: center;
		margin-top: 10px;
		margin-right: 9.7px;
		height: 270px;
		cursor: pointer;
	}

	.uploadingimg p {
		text-align: center;
		font-size: 14px;
		color: #ccc;
	}

	.uploadingimg img {
		/* transform: translat(calc(-50% + 70 px), calc(-50% + 70 px)); */
		display: block;
		width: 180px;
		margin: 0 auto;
	}

	.textoverfloat {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.uploadingimgtwo {
		width: 264px;
		background-color: #fff;
		margin-top: 10px;
		margin-right: 9.7px;
		color: #999;
		cursor: pointer;
		box-shadow: 0px 0px 6px #c4c4c4;
	}

	.uploadingimgtwo:hover {
		/* background-color: #c5dcff; */
	}

	.uploadingimgtwo .showoperation {
		display: none;
		padding-top: 14px;
	}

/* 	.uploadingimgtwo:hover .showoperation {
		width: 100%;
		justify-content: space-between;
		display: flex;
	}
 */
/* 	.uploadingimgtwo:hover .goldcoin {
		display: none;
	}
 */
	.uploadingimgtwo>img {
		width: 234px;
		height: 199px;
		padding: 10px 15px 0;
	}

	.uploadingimgtwo p {
		font-size: 14px;
		margin-left: 13px;
		line-height: 40px;
	}

	.up-bott-font {
		margin-left: 12px;
		display: flex;
		justify-content: space-between;
		margin-right: 20px;
		line-height: 40px;
	}

	.up-bott-font span {
		display: block;
		font-size: 12px;
	}

	.QRcodeclass {
		position: absolute;
		bottom: -200px;
		left: 0px;
		background: wheat;
		display: block;
		width: 200px;
		height: 200px;
	}

	.imgbox {
		height: 210px;
		overflow: hidden;
		padding-left: 10px;
		padding-right: 10px;
		vertical-align: middle;
		display: table-cell;
	}

	.text {
		font-size: 12px !important;
		color: #000;
		line-height: 17px;
		margin-left: 5px;
		cursor: pointer;
	}

	.text:hover {
		color: #0000cc;
	}

	.data_title {
		font-size: 18px;
		font-weight: bold;
		height: 30px;
		line-height: 30px;
	}

	/deep/.el-dialog {
		margin-top: 10vh !important;
	}

	>>>.el-dialog__body {
		padding-top: 0px;
	}

	.dialog_uploadingimgtwo {
		width: 200px;
		height: 200px;
		margin-right: 6.3px;
	}

	>>>.el-radio__input.is-checked .el-radio__inner {
		background: #b6492c;
		border-color: #b6492c;
	}

	>>>.el-radio__input.is-checked+.el-radio__label {
		color: #b6492c;
	}
	
	
	
</style>
