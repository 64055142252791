<template>
	
	<!-- 二维码列表 -->
	<div class="mains">
		<!-- 筛选组件 -->
		<el-row style=" overflow: hidden;border-radius: 5px;box-shadow: 0px 0px 6px #c4c4c4;">
			<el-row :gutter="20" style="padding:10px; border-bottom:1px dashed #eeeeee; background: white;height: 146px">
				<el-row>
					<el-col :span="4">
						<p style="font-size: 14px; font-weight: bold; line-height: 40px; height: 40px; display: block; text-indent: 32px">我的工程</p>
					</el-col>
					<el-col :span="5" :push="15">
						<p style="font-size: 14px; color: #CCCCCC; line-height: 40px; height: 40px; display: block;">（总空间{{session_total_storage/1024}}G/已用{{session_use_storage}}M)</p>
					</el-col>
				</el-row>
				<el-row style="line-height: 73px">
					<el-col :span="15">
						<el-radio-group v-model="fiter_type_radio_group" @change="fiter_type_radio_group_change()" style="margin-left: 32px;">
							<el-radio :label="null" size="mini">全部</el-radio>
							<el-radio :label="1" size="mini">模型</el-radio>
							<el-radio :label="2" size="mini">动画</el-radio>
							<el-radio :label="3" size="mini">脚本</el-radio>
						</el-radio-group>
					</el-col>
					<!-- 搜索框 -->
					<el-col :span="8">
						<!-- <el-input placeholder="请输入内容" v-model="input_search" class="input-with-select" @keyup.enter.native="btn_click_search()"
						 style="width:380px;height:  42px;"> -->
						</el-input>
					</el-col>
				</el-row>
			</el-row>
		</el-row>
		<h2 class="data_title" v-show="QRcode_list.length>0">我的</h2>
		<el-row :span="24">
			<el-col :span="5" class='hoverbox' style="background: white; margin-bottom: 20px; margin-right:10px;width: 24%;" v-for="(goodsData1, index) in QRcode_list"
			 :key="index">
			 
			 	<!-- 资源列表 -->
			 	    <el-col style="" class='box' >
			 	        <el-col :span="24" style='height: 183px; background: #e6e6e6; overflow: hidden; position: relative;'>
			 				<div  class="imgbox">
			 					<vue-qr :logoSrc="imageUrl" :text="goodsData1.qrcode_url" :size="180" ref="qrimg" style="margin-left: 45px; display: block;"></vue-qr>
			 				</div>
			 				<!-- <div style="position: absolute; right: 6px; top: 6px; cursor: pointer;" @click="fabulous(goodsData1)">
			 					<p><img :src=" goodsData1.fabulous==1?imgurlhover:imgurl" alt="" style="width: 20px;"></p>
			 				</div> -->
			 	        </el-col>
			 	        <el-col class="listbuttom_data">
			 				<div :title="goodsData1.name" class="list_top_data">
			 				    <span style="font-size: 16px; color: #000000;" class="text_over">{{goodsData1.code_name}}</span> <span>{{goodsData1.account_id}}</span>
			 				</div>
			 				<div style="height: 41px;line-height: 41px;color: #787777;" class='xiazai'>
			 				    <span >{{goodsData1.goldcoin}}币</span><span>下载量：{{goodsData1.download_number}}</span>
			 					<div class="tran_buttom">
			 						<span  @click="downsimg(index)" style="cursor: pointer;"class='shoucang' >
			 							<p><img src="../../assets/img/xiazai.svg" alt="" width="24px"  class="bottom_icon"></p>  <span class="texthover">下载</span>
			 						</span>
			 						<span  @click="DeleteQRcode(goodsData1)" style="cursor: pointer; border-left: 1px solid rgba(255,255,255,0.5); border-right: 1px solid rgba(255,255,255,0.5);"class='shoucang' >
			 							<p><img src="../../assets/img/gowuche.svg" alt="" width="24px;" class="bottom_icon"></p> <span class="texthover">删除</span>
			 						</span>
			 						
			 					</div>
			 				</div>
			 			</el-col>
			 	    </el-col>
			 
				<!-- <el-row>
					<el-col :offset="22"></el-col>
				</el-row>
				<el-row> -->
					<!-- 二维码 -->
					
				<!-- </el-row> -->
				<!-- <el-row :span="24">
					<el-col :span="22" :offset="2">
						<p style="font-size: 14px; color: #C8C8C8; line-height:30px;" class="text_over">{{item.code_name}}</p>
					</el-col>
					<el-col :span="6" :offset="2" style="margin-bottom: 10px; display: flex;">
						<el-button size="mini" @click="downsimg(index)">下载</el-button>
						<el-button size="mini" @click="DeleteQRcode(item)" type="danger">删除</el-button>
					</el-col>
				</el-row> -->
			</el-col>
		</el-row>
		<!-- 分页 -->
		<el-pagination background layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size="pagesize"
		 :current-page="currentPage" :total="allpage" style="margin-left: calc(40%);margin-top: 20px;" v-show='allpage>=15'>
		</el-pagination>
		
		
		<h2 class="data_title" v-show="QRcode_list.length>0">收藏</h2>
		<el-row :span="24">
			
			
			
			
			<el-col :span="5" class='hoverbox' style="background: white; margin-bottom: 20px; margin-right:10px;width: 24%;" v-for="(goodsData2, index) in QRcode_list_tow"
			 :key="index">
			 
			 	<!-- 资源列表 -->
			 	    <el-col style="" class='box' >
			 	        <el-col :span="24" style='height: 183px; background: #e6e6e6; overflow: hidden; position: relative;'>
			 				<div  class="imgbox">
								
			 					<vue-qr :logoSrc="imageUrl" :text="goodsData2.qrcode_url" :size="180" ref="qrimg" style="margin-left: 45px; display: block;"></vue-qr>
			 				</div>
			 				<!-- <div style="position: absolute; right: 6px; top: 6px; cursor: pointer;" @click="fabulous(goodsData1)">
			 					<p><img :src=" goodsData1.fabulous==1?imgurlhover:imgurl" alt="" style="width: 20px;"></p>
			 				</div> -->
			 	        </el-col>
			 	        <el-col class="listbuttom_data">
			 				<div :title="goodsData2.name" class="list_top_data">
			 				    <span style="font-size: 16px; color: #000000;" class="text_over">{{goodsData2.code_name}}</span> <span>{{goodsData2.account_id}}</span>
			 				</div>
			 				<div style="height: 41px;line-height: 41px;color: #787777;" class='xiazai'>
			 				    <span >{{goodsData2.goldcoin}}币</span><span>下载量：{{goodsData2.download_number}}</span>
			 					<div class="tran_buttom">
			 						<span  @click="downsimg(index)" style="cursor: pointer;"class='shoucang' >
			 							<p><img src="../../assets/img/xiazai.svg" alt="" width="24px"  class="bottom_icon"></p>  <span class="texthover">下载</span>
			 						</span>
			 						<span  @click="DeleteQRcode(goodsData2)" style="cursor: pointer; border-left: 1px solid rgba(255,255,255,0.5); border-right: 1px solid rgba(255,255,255,0.5);"class='shoucang' >
			 							<p><img src="../../assets/img/gowuche.svg" alt="" width="24px;" class="bottom_icon"></p> <span class="texthover">删除</span>
			 						</span>
			 						
			 					</div>
			 				</div>
			 			</el-col>
			 	    </el-col>
			 
				<!-- <el-row>
					<el-col :offset="22"></el-col>
				</el-row>
				<el-row> -->
					<!-- 二维码 -->
					
				<!-- </el-row> -->
				<!-- <el-row :span="24">
					<el-col :span="22" :offset="2">
						<p style="font-size: 14px; color: #C8C8C8; line-height:30px;" class="text_over">{{item.code_name}}</p>
					</el-col>
					<el-col :span="6" :offset="2" style="margin-bottom: 10px; display: flex;">
						<el-button size="mini" @click="downsimg(index)">下载</el-button>
						<el-button size="mini" @click="DeleteQRcode(item)" type="danger">删除</el-button>
					</el-col>
				</el-row> -->
			</el-col>
			
		</el-row>
		<!-- 分页 -->
		<el-pagination background layout="prev, pager, next, jumper" @current-change="handleCurrentChange_tow" :page-size="pagesize_tow"
		 :current-page="currentPage_tow" :total="allpage_tow" style="margin-left: calc(40%);margin-top: 20px;" v-show='allpage_tow>=15'>
		</el-pagination>
		
		
		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
			<span>删除将无法恢复,请谨慎删除</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="beleqr()">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import {
		//二维码列表
		requestaccount_code_list,
		//删除二维码
		account_code_delete,
		requestothers_code_list,
		others_code_delete,
		requestStorage
	} from "../../api/request.js";
	//二维码插件
	import vueQr from "vue-qr";
	export default {
		name: "mains",
		components: {
			vueQr,
		},
		props: {
			//全部选中二维码
			checkbox_all: {
				type: Boolean,
			},
			QRdata: {
				type: Array,
			},

		},
		data() {
			return {
				//二维码资源
				QRcode_list: [],
				QRcode_list_tow: [],
				//多选
				checked: false,
				//二维码生成
				imageUrl: require("../../assets/img/erweimalogo.png"),
				//删除二维码弹出框
				dialogVisible: false,
				qrdata: null,
				//分页全部资源总条数
				allpage:null,
				//当前分页
				currentPage:null,
				//当前页面显示几条数据
				pagesize:null,
				
				//分页全部资源总条数
				allpage_tow:null,
				//当前分页
				currentPage_tow:null,
				//当前页面显示几条数据
				pagesize_tow:null,
				fiter_type_radio_group:null,
				session_total_storage: null,
				session_use_storage: null,
				input_search:''
			};
		},
		watch: {},
		methods: {
			//分页处理函数
			handleCurrentChange(val){
				window.scrollTo(0, 0);
				this.UpQRData(val)
			},
			//分页处理函数
			handleCurrentChange_tow(val){
				window.scrollTo(0, 0);
				this.UpQRData_tow(val)
			},
			//下载二维码
			downsimg(index) {
				this.downloadFile("二维码", this.$refs.qrimg[index].$el.currentSrc);
			},
			//删除二维码
			DeleteQRcode(item) {
				this.dialogVisible = true;
				this.qrdata = item;
			},
			//删除二维码 提示 确定按钮
			beleqr() {
				let data = {
					id: this.qrdata.id,
				};
				let _this = this;
				if(this.qrdata.istype==0){
					others_code_delete(data).then((res) => {
						this.dialogVisible = false;
						this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$refs.MyShowRightProject.click_upload(false)
						requestaccount_code_list().then((res) => {
							_this.QRcode_list = [];
							for (let i = 0; i < res.ret.data.length; i++) {
								if (res.ret.data[i].qrcode_url == null) {
									res.ret.data[i].qrcode_url = 'default_url';
								}
								if (res.ret.data[i].is_code != 0) {
									_this.QRcode_list.push(res.ret.data[i]);
								}
							}
						});
					});
				}else if(this.qrdata.istype==1){
					account_code_delete(data).then((res) => {
						this.dialogVisible = false;
						this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$refs.MyShowRightProject.click_upload(false)
						requestaccount_code_list().then((res) => {
							_this.QRcode_list = [];
							for (let i = 0; i < res.ret.data.length; i++) {
								if (res.ret.data[i].qrcode_url == null) {
									res.ret.data[i].qrcode_url = 'default_url';
								}
								if (res.ret.data[i].is_code != 0) {
									_this.QRcode_list.push(res.ret.data[i]);
								}
							}
						});
					});
				}
				
			},

			//下载全部二维码
			downloadFile_all: function() {
				for (let i = 0; i < this.QRcode_list.length; i++) {
					this.downloadFile("二维码", this.$refs.qrimg[i].$el.currentSrc);
				}
			},
			//下载二维码
			downloadFile(fileName, content) {
				let aLink = document.createElement("a");
				let blob = this.base64ToBlob(content);
				let evt = document.createEvent("HTMLEvents");
				evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
				aLink.download = fileName;
				aLink.href = URL.createObjectURL(blob);
				aLink.dispatchEvent(
					new MouseEvent("click", {
						bubbles: true,
						cancelable: true,
						view: window,
					})
				); //兼容火狐
			},
			//下载二维码转换base64
			base64ToBlob(code) {
				let parts = code.split(";base64,");
				let contentType = parts[0].split(":")[1];
				let raw = window.atob(parts[1]);
				let rawLength = raw.length;
				let uInt8Array = new Uint8Array(rawLength);
				for (let i = 0; i < rawLength; ++i) {
					uInt8Array[i] = raw.charCodeAt(i);
				}
				return new Blob([uInt8Array], {
					type: contentType
				});
			},
			//请求二维码列表数据
			 UpQRData:function(val){
				this.QRcode_list = [];
				requestaccount_code_list(val).then((res) => {
					this.allpage=this.QRcode_list.length;
					this.currentPage=res.ret.current_page;
					this.pagesize=res.ret.per_page;
					for (let i = 0; i < res.ret.data.length; i++) {
						if (res.ret.data[i].qrcode_url == null) {
							res.ret.data[i].qrcode_url = 'default_url';
						}
						if (res.ret.data[i].is_code != 0) {
							this.QRcode_list.push(res.ret.data[i]);
						}
						res.ret.data[i].istype=1;
					}
				});
			},
			UpQRData_tow:function(val){
				let data={}
				requestothers_code_list(data,val).then((res) => {
					this.allpage_tow=this.QRcode_list.length;
					this.currentPage_tow=res.ret.current_page;
					this.pagesize_tow=res.ret.per_page;
					for (let i = 0; i < res.ret.data.length; i++) {
						if (res.ret.data[i].qrcode_url == null) {
							res.ret.data[i].qrcode_url = 'default_url';
						}
						if (res.ret.data[i].is_code != 0) {
							this.QRcode_list_tow.push(res.ret.data[i]);
						}
						res.ret.data[i].istype=0;
					}
					
				});
			},
			fiter_type_radio_group_change(){
			}
			
		},
		mounted() {
			let _this = this;
			this.UpQRData()
			this.UpQRData_tow();
			if(JSON.parse(localStorage.getItem('storage'))==null){
				let data = {};
				requestStorage(data).then((res) => {
					console.log(res);
					localStorage.setItem('storage',JSON.stringify(res.ret))
					this.session_total_storage = res.ret.total_storage;
					this.session_use_storage = res.ret.use_storage;
				});
			}else{
				this.session_total_storage = JSON.parse(localStorage.getItem('storage')).total_storage;
				this.session_use_storage = JSON.parse(localStorage.getItem('storage')).use_storage;
			}
		},
	};
</script>
<style scoped>
	@import "../../style/common.css";
	.mains{
		width: 100%;
	}
	.uploadingimgtwo {
		width: 264px;
		height: 260px;
		background-color: #fff;
		margin-top: 10px;
		margin-right: 9.7px;
		color: #999;
	}

	.uploadingimgtwo img {
		width: 227px;
		height: 205px;
		padding: 10px 15px 0;
	}

	.uploadingimgtwo p {
		font-size: 14px;
		margin-left: 13px;
	}
	.text_over{
		white-space:nowrap;
		overflow:hidden;
		text-overflow:ellipsis;
	}
	.data_title {
		font-size: 16px;
		font-weight: bold;
		height: 30px;
		line-height: 30px;
	}
	
	
	
	
	.hoverbox{
		width: 263px;
		height: 266px;
		border-radius: 4px;
		overflow: hidden;
		margin-right: 12px;
		box-shadow: 0px 0px 6px #c4c4c4;
		margin-bottom: 30px;
	}
	.box{background: #fff;}
	.box.imgbox img{
		/* width: 100%; */
		/* transform: scale(0.5); */
	}
	.hoverbox:hover{
		box-shadow: 0px 0px 6px 0px rgba(196,62,27,0.75);
	}
	.hoverbox:hover .tran_buttom{
		bottom: 0px;
	}
	.box .imgbox{
		/* transform: translateY(calc(50% - 55px)); */
		display:table-cell;
		vertical-align:middle;
		text-align:center;
		height: 183px;
		overflow: hidden;
		margin: 0 auto;
		width: 180px;
	}
	
	.xiazai{
		 justify-content: space-between; display: flex;
		 margin-left: 6px;
		 margin-right: 6px;
	}
	
	.box:hover .imgbox{
		overflow: hidden;
	}
	/* .box:hover .imgbox img{ transform: scale(1.2); transition: 1s;} */
	.texthover{
		display: block;
		color: white;
		text-align: center;
	}
	.list_top_data{
		height: 41px;
		line-height: 41px;
		border-bottom: 1px solid #ebebeb;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		color: #787777;
		justify-content: space-between; 
		display: flex;
		margin-right: 6px;
		margin-left: 6px;
	}
	.listbuttom_data{
		position: relative;
		background-color: #f8f8f8;
		border-radius: 0 0 5px 5px;
		overflow: hidden;
	}
	.tran_buttom{
		width: 100%;
		display: flex;
		position: absolute;
		bottom: -83px;
		left: 0px;
		transition: 0.5s;
	}
	.shoucang{
		width: 50%;
		height: 83px;
		background-color:rgba(196,62,28,0.9);
		display: block;
		
	}
	.shoucang:hover{
		background-color:rgba(255,146,98,0.9);
	}
	.bottom_icon{
		margin: 15px auto 0 auto; display: block;
	}
	.text_over{
		white-space:nowrap;
		overflow:hidden;
		text-overflow:ellipsis;
	}
	>>>.el-radio__input.is-checked .el-radio__inner {
		background: #b6492c;
		border-color: #b6492c;
	}
	
	>>>.el-radio__input.is-checked+.el-radio__label {
		color: #b6492c;
	}
</style>
