<template>
	<div class="MyShow">
		<el-row class="MyShow-center-text">
			<el-row class="MyShow-center">
				<!-- 我的工程左侧公共区域 -->
				<el-col style="width: 277px">
					<MyShowLeft ref="MyShowLeft"></MyShowLeft>
				</el-col>
				<!-- 我的工程右侧区域 -->
				<el-col style="width: 1100px;margin-left: 20px" v-show="current_select_sequence == 1">
					<MyShowRightProject ref="MyShowRightProject"></MyShowRightProject>
				</el-col>
				<!-- 我的收藏右侧区域 -->
				<el-col style="width: 1100px;margin-left: 20px" v-show="current_select_sequence == 2">
					<MyShowRightCollect ref="MyShowRightCollect"></MyShowRightCollect>
				</el-col>
				<!-- 上传记录右侧区域上传记录 -->
				<el-col  style="width: 1100px;margin-left: 20px"  v-show="current_select_sequence == 3">
					<MyShowRightShare ref="MyShowRightShare"></MyShowRightShare>
				</el-col>
				<!-- 购买记录 -->
				<el-col style="width: 1100px;margin-left: 20px" v-show="current_select_sequence == 5">
					<MyShowgomaijilu ref="MyShowgomaijilu"></MyShowgomaijilu>
				</el-col>
				<!-- 上传记录右侧区域上传 -->
				<el-col style="width: 1100px;margin-left: 20px"  v-show="current_select_sequence == 4">
					<ModeUpload ref="ModeUpload"></ModeUpload>
				</el-col>
				<!-- 我的二维码 -->
				<el-col style="width: 1100px;margin-left: 20px"  v-show="current_select_sequence == 6">
					<MyShowRightProjectCode ref="MyShowRightProjectCode"></MyShowRightProjectCode>
				</el-col>
			</el-row>
		</el-row>
	</div>
</template>
<script>
	//左侧折叠面板组件
	import MyShowLeft from "@/views/MyShow/MyShowLeft.vue";
	//我的工程右侧界面
	import MyShowRightProject from "@/views/MyShow/MyShowRightProject.vue";
	//我的收藏右侧界面
	import MyShowRightCollect from "@/views/MyShow/MyShowRightCollect.vue";
	//购买记录
	import MyShowgomaijilu from "@/views/MyShow/MyShowgomaijilu.vue";
	//上传记录右侧区域上传记录
	import MyShowRightShare from "@/views/MyShow/MyShowRightShare.vue";
	//上传记录右侧区域上传
	import ModeUpload from "@/views/ModeUpload.vue";
	//我的二维码
	import MyShowRightProjectCode from "@/views/MyShow/MyShowRightProjectCode.vue";
	export default {
		data() {
			return {
				current_select_sequence: 0,
			};
		},
		components: {
			MyShowLeft,
			MyShowRightProject,
			MyShowRightCollect,
			MyShowRightShare,
			ModeUpload,
			MyShowgomaijilu,
			MyShowRightProjectCode
		},

		methods: {
			set_current_select_sequence: function(v) {
				return (this.current_select_sequence = v);
			},
		},
		watch:{
			'$route':function(){
				if (this.$route.query.ids == '4') {
					
					this.current_select_sequence=4;
					this.$refs.MyShowLeft.activeNames=0;
				} else {
					this.$router.push({
						query: {
							level_0: 3
						}
					});
				}
			}
		},
		mounted() {
			this.MyShow.MyShowVue = this;
			this.$refs.MyShowLeft.init(this);
			this.$refs.MyShowRightProject.init(this);
			this.$refs.MyShowRightCollect.init(this);
			this.MyShow.level_one_list = [];
			this.MyShow.level_two_list = [];
			this.MyShow.resource_list = [];
			
			
			console.log(this.current_select_sequence)
		},
	};
</script>
<style scoped>
	@import "../../style/common.css";

	.MyShow-center-text {
		background: #f8f8f8;
	}

	.MyShow-center {
		width: 1381px;
		background-color: #f8f8f8;
		padding: 15px;
		display: flex;
		justify-content: flex-start;
		margin: 0 auto;
	}

	.MyShow-right-text {
		width: 1261px;
		background-color: #d1e1f9;
		overflow: hidden;
		
	}

	.icon {
		width: 20px;
		background: url(../../assets/img/close.png) no-repeat center center;
		display: block;
		height: 15px;
		cursor: pointer;
	}

	.icon2 {
		background: url(../../assets/img/转移.png) no-repeat center center;
	}

	.MyShowCode-navbar {
		height: 40px;
		line-height: 40px;
		background-color: #fff;
		display: flex;
		justify-content: flex-start;
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.MyShowCode-navbar {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
	}

	.MyShowCode-navbar span {
		margin-left: 13px;
		margin-right: 13px;
	}

	.MyShowCode-navbar span b {
		margin-left: 15px;
	}

	.weima {
		height: 51px;
		line-height: 51px;
		background-color: #fff;
		justify-content: flex-end;
		margin-top: 5px;
	}

	.weima span {
		color: #000000;
		font-size: 14px;
		margin-right: 10px;
		cursor: pointer;
	}

	.MyShow-right-text-img {
		width: 1261px;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	.uploadingimg {
		width: 264px;
		background-color: #fff;
		height: ;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 10px;
		margin-right: 9.7px;
		height: 270px;
		cursor: pointer;
	}

	.uploadingimg img {
		/* transform: translat(calc(-50% + 70 px), calc(-50% + 70 px)); */
		display: block;
		width: 180px;
	}

	.uploadingimg p {
		margin-left: 103px;
		margin-top: 20px;
		font-size: 14px;
		color: #c8c8c8;
	}

	.uploadingimgtwo {
		width: 264px;
		height: 270px;
		background-color: #fff;
		margin-top: 10px;
		margin-right: 9.7px;
		color: #999;
	}

	.uploadingimgtwo>img {
		width: 227px;
		height: 205px;
		padding: 10px 15px 0;
	}

	.uploadingimgtwo p {
		font-size: 14px;
		margin-left: 13px;
		line-height: 20px;
	}

	.up-bott-font {
		margin-left: 12px;
		display: flex;
		justify-content: space-between;
		margin-right: 13px;
	}

	.up-bott-font span {
		display: block;
		font-size: 12px;
	}

	.QRcodeclass {
		position: absolute;
		top: -210px;
		left: -160px;
		background: wheat;
		display: block;
		width: 200px;
		height: 200px;
	}

	.add_img {}
</style>
