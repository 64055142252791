<template>
	<el-row class="main">
    <el-button size="mini" style="width: 190px;height: 42px; display: block; margin: 15px auto 15px; border-color:#c43e1b; 
">
        <p  style="line-height: 26px;" @click="upload"> <img src="../../assets/img/upload_icon.svg" alt="" style="width: 20px;margin-right: 10px; transform: translateY(2px)"><span style="font-size: 17px; color: #c43e1b;">上传</span></p>
    </el-button>
		<!-- element折叠面板 -->
		<el-collapse accordion v-model="activeNames" @change="collapse_change(item)" v-for="(item,index_list) in projectList"
		 :key="index_list" :ref="item.name">
			<el-collapse-item :name="item.sequence">
				<template slot="title">
					<span ref="name" :class="activeNames==item.sequence? 'active_title' : ''" :id="item.id" style="text-indent: 20px;">
						{{
            item.name
            }}
					</span>
          <p @click="click_add()" style="cursor: pointer; margin-left: 58%; width: 20px;" v-show="index_list < 2">
            <img src="../../assets/img/add.png" />
          </p>
				</template>
				<transition name="el-fade-in-linear">
					<ul>
						<el-col class="mall_nav_list" v-for="(item2, index) of Child_list" :key="index">
							<!-- hover提示 -->
							<el-tooltip class="item" effect="dark" :disabled="tooltip_disabled" :content="item2.name" placement="top">
								<li class="fl" :class="{
                                        active_list:
                                            current_select_level2_item !=
                                                null &&
                                            current_select_level2_item.id ==
                                                item2.id,
                                    }"
								 style="cursor: pointer;text-indent: 30px; " :key="index" @mousemove="MousePassing(item2.name)" @click="click_level2_item(item2)">
									<span style="color:#0000ff;" v-show="index_list < 2"><img src="../../assets/img/Myshow_left_icon.svg" alt="" style="width: 14px"></span>
									{{ item2.name }}
								</li>
							</el-tooltip>
              <li class="fr">
                <el-popover
                    placement="top">
                  <div style=" margin: 0">
                    <ul>
                      <li>
                        <!-- 删除文件夹 -->
                        <p class="myshow_let_tips_text" @click="
                                        click_delete(item2.id, item2.node_id,item)
                                    "
                           v-show="index_list < 2">删除</p>
                        <!-- 修改文件夹名称 -->
                        <p @click="click_modify(item2.id)" v-show="index_list < 2" class="myshow_let_tips_text">修改</p>
                      </li>
                    </ul>
                  </div>
                 <i v-show="index_list < 2" slot="reference"  style="font-weight: bold; line-height: 20px; cursor: pointer">...</i>
                </el-popover>
              </li>
						</el-col>
<!--						<el-col>-->
<!--							&lt;!&ndash; 添加文件夹 &ndash;&gt;-->
<!--							<li @click="click_add()" style="cursor: pointer; margin-left: 30px;width: 20px;" v-show="index_list < 2">-->
<!--								<img src="../../assets/img/add.png" />-->
<!--							</li>-->
<!--						</el-col>-->
					</ul>
				</transition>
			</el-collapse-item>
		</el-collapse>
		<!-- 修改名称 -->
		<el-dialog title="修改名称" :visible.sync="dialogVisible_modify" width="30%">
			<el-input v-model="modify_input" placeholder="请输入内容" maxlength="8"></el-input>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible_modify = false">取 消</el-button>
				<el-button type="primary" @click="dialog_btn_click_modify()">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 增加目录 -->
		<el-dialog title="增加目录" :visible.sync="dialogVisible_add" width="30%">
			<el-input v-model="add_ProjectList_input" placeholder="请输入内容" maxlength="8"></el-input>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible_add = false">取 消</el-button>
				<el-button type="primary" @click="dialog_btn_click_add()">确 定</el-button>
			</span>
		</el-dialog>
		<div id="aaa">
			
		</div>
	</el-row>
</template>
<script>
	import {
		// 我的目录列表
		requestdirectory_list,
		// 添加文件夹
		requestdirectory_add,
		//修改文件夹
		requestdirectory_modify,
		//我的工程资源
		requestaccount_resource_list,
		//删除文件夹
		requestdirectory_delete,
		//我的收藏资源
		requestaccount_account_list,
		requestaccount_project_list,
		
	} from "../../api/request.js";
	
	export default {
		data() {
			return {
				//折叠面板显示与影藏
				accordion: "el-icon-arrow-up",
				//控制折叠面板显示与隐藏
				activeNames: 1,
				//修改工程弹出框
				dialogVisible_modify: false,
				//修改输入框的绑定值
				modify_input: "",
				//要修改的项的数据
				modify_item_data: null,
				// 增加工程弹出框
				dialogVisible_add: false,
				//添加输入框的绑定值
				add_ProjectList_input: "",
				//二级列表
				Child_list: [],
				//一级列表
				projectList: [],
				//是否显示提示
				tooltip_disabled: true,
				//当前选中一级目录的项的数据
				current_select_level1_item: null,
				//当前选中二级目录的项的数据
				current_select_level2_item: null,
				//父组件
				parent: null,

			};
		},
		mounted() {
			let _this = this;
			let data = {
				parent_id: 0,
			};
			//页面挂载时请求一级目录文件夹
			requestdirectory_list(data).then((res) => {
				res.ret.push({
					id: 400000000,
					level: 1,
					max_level: 2,
					name: "我的购买",
					parent_id: 0,
					parent_node_id: 0,
					sequence: 4,
				})
				res.ret.push({
					id: 500000000,
					level: 1,
					max_level: 2,
					name: "我的二维码",
					parent_id: 0,
					parent_node_id: 0,
					sequence: 5,
				})
				if (this.$route.query.ids == "4") {
					this.initupload();
				} else {
					this.OpenByDefault(res.ret[0], 3);
				}
				//挂载数据
				_this.MyShow.init_level_one_list(res.ret);
				_this.projectList = _this.MyShow.level_one_list;
			});
		},
		methods: {
			/********************************************************************************************************
			 * 供其他组件调用的接口
			 *******************************************************************************************************/
			//初始化
			init: function(_parent) {
				this.parent = _parent;
			},
			upload(){
				this.parent.current_select_sequence=4;
				this.activeNames = 0;
			},
			initupload: function() {
				this.activeNames = 0;
				this.parent.set_current_select_sequence(4);
			},
			//返回二级目录数据
			get_level2_list: function() {
				return this.Child_list;
			},
			//页面挂载时显示我的工程处理函数
			OpenByDefault2: function(item) {
				this.OpenByDefault(this.current_select_level1_item, item);
			},
			//请求刷新当前选中的二级目录的资源列表数据(如果当前没有选中二级目录，refresh_all=false不更新全部资源 refresh_all=true更新全部资源)
			request_current_resource_list: function(
				condition_state = null, //状态
				condition_is_animation = null, //筛选
				page = null, //页数
				refresh = true, //刷新
				refresh_all = true, //刷新全部
				search = null
			) {
				if (null == this.current_select_level2_item) {
					if (refresh_all) {
						this.request_resource_list(
							this.current_select_level1_item,
							refresh,
							condition_state,
							condition_is_animation,
							page,
							search
						);
					}
					return;
				}
				this.request_resource_list(
					this.current_select_level2_item,
					refresh,
					condition_state,
					condition_is_animation,
					page,
					search
				);
			},
			//请求资源列表并刷新其他目录数据(非当前目录)
			request_resource_list_other_directory: function(
				item,
				refresh = true,
				condition_state = null,
				condition_is_animation = null,
				page = null,
				search = null,
				parent_node_id = null
			) {
				this.request_resource_list(
					item,
					refresh,
					condition_state,
					condition_is_animation,
					page,
					search,
					this.current_select_level1_item.node_id
				);
			},
			/********************************************************************************************************
			 * 当前组件调用的一些通用接口
			 *******************************************************************************************************/
			//获取选中的一级菜单 1：我的工程，2：我的收藏，3：我的分享
			get_level_one_selected: function(val) {
				if (val == 2) {
					return 2;
				}
				if (val == 1) {
					return 2;
				}
				if (
					this.current_select_level1_item.level == 1 &&
					this.current_select_level1_item.sequence == 1
				) {
					return 1;
				} else if (
					this.current_select_level1_item.level == 1 &&
					this.current_select_level1_item.sequence == 2
				) {
					return 2;
				} else if (
					this.current_select_level1_item.level == 1 &&
					this.current_select_level1_item.sequence == 3
				) {
					return 3;
				}
				return 0;
			},
			//设置右边界面数据
			set_right_list_data(
				data,
				per_page = null,
				current_page = null,
				total = null
			) {
				let level_one_type = this.get_level_one_selected();
				if (1 == level_one_type) {
					if (this.parent == null) {
						return;
					}

					this.parent.$refs.MyShowRightProject.refresh_data_project(
						data,
						per_page,
						current_page,
						total
					);
				}
				if (2 == level_one_type) {
					this.parent.$refs.MyShowRightCollect.refresh_data_collect(
						data,
						per_page,
						current_page,
						total
					);
				}
			},

			/********************************************************************************************************
			 * 二级目录数据相关接口
			 *******************************************************************************************************/
			//请求二级目录并刷新数据(添加、删除、修改 使用)
			request_level2_list: function() {
				let reques_data = {
					parent_id: this.current_select_level1_item.id,
				};
				let _this = this;
				requestdirectory_list(reques_data).then((res) => {
					if (this.activeNames == 3) {
						res.ret.push({
							name: "模型",
							id: 997,
						});
						res.ret.push({
							name: "动画",
							id: 998,
						});
						res.ret.push({
							name: "脚本",
							id: 996,
						});
					}
					_this.MyShow.set_level_two_list(
						_this.current_select_level1_item.id,
						res.ret
					);
					//二级列表赋值
					_this.Child_list = res.ret;
					_this.parent.$refs.MyShowRightProject.levl2_options_list = res.ret;
				});
			},
			//刷新二级目录数据,无数据向服务器请求（点击一级目录时使用）
			refresh_level2_list: function() {
				let level_two_list = this.MyShow.get_level_two_list(
					this.current_select_level1_item.id
				);
				if (level_two_list != null) {
					this.Child_list = level_two_list;
					return;
				}
				this.request_level2_list();
			},
			/********************************************************************************************************
			 * 右边资源列表数据相关接口
			 *******************************************************************************************************/
			//请求资源列表并刷新数据
			request_resource_list: function(
				item,
				refresh = true,
				condition_state = null,
				condition_is_animation = null,
				page = null,
				search = null,
				parent_node_id = null
			) {
				let id = item.id;
				let level = item.level;
				let sequence = item.sequence;
				let name = search;
				let node_id_max = item.node_id_max; //node_id_max
				let node_id_min = item.node_id_min; //node_id_min
				if (null == parent_node_id) {
					parent_node_id = 0;
					if (null != this.current_select_level2_item) {
						if (this.current_select_level1_item == null) {
							return;
						}
						if (
							this.current_select_level1_item.id == 997 &&
							this.current_select_level1_item.id == 998
						) {
							return;
						}
						parent_node_id = this.current_select_level1_item.node_id;
					}
				}
				if (null == condition_state) {
					condition_state = this.DefineType.condition_state_all;
				}
				// if (null == condition_is_animation) {
				// 	condition_is_animation = this.DefineType.condition_type_all;
				// }
				if (null == page) {
					page = null;
				}
				// let request_data = {
				// 	state: condition_state,
				// 	is_animation: condition_is_animation,
				// 	node_id_min: node_id_min,
				// 	node_id_max: node_id_max,
				// 	name: name,
				// };
				let request_data = {
					state: condition_state,
					is_animation: condition_is_animation,
					node_id_min: node_id_min,
					node_id_max: node_id_max,
					name: name,
				};
				let level_one_type = this.get_level_one_selected();
				if (1 == level_one_type) {
					//请求资源列表
					let _this = this;
					let _id = id;
					requestaccount_project_list(request_data, page).then((res) => {
						_this.MyShow.set_resource_list(_id, res.ret);
						if (refresh) {
							_this.set_right_list_data(
								res.ret,
								res.ret.per_page,
								res.ret.current_page,
								res.ret.total
							);
						}
					});
				}
				if (2 == level_one_type) {
					//请求资源列表
					let _this = this;
					let _id = id;
					requestaccount_account_list(request_data, page).then((res) => {
						_this.MyShow.set_resource_list(_id, res.ret);
						if (refresh) {
							_this.set_right_list_data(
								res.ret,
								res.ret.per_page,
								res.ret.current_page,
								res.ret.total
							);
						}
					});
				}
			},
			//刷新资源列表数据,无数据向服务器请求
			refresh_resource_list: function(
				item,
				refresh = true,
				condition_state = null,
				condition_is_animation = null,
				page = null
			) {
				let data_list = this.MyShow.get_resource_list(item.id);
				if (data_list != null) {
					this.set_right_list_data(data_list);
					return;
				}
				this.request_resource_list(
					item,
					refresh,
					condition_state,
					condition_is_animation,
					page
				);
			},
			/********************************************************************************************************
			 * 操作 事件 相关接口
			 *******************************************************************************************************/
			//手风琴按钮（点击一级菜单）
			collapse_change: function(data) {
				// this.$refs.上传记录[0].$el.children[0].children[1].style.border = 'none';
				// this.$refs.上传记录[0].$el.children[0].children[0].children[0].style.borderBottom = '2px solid #EBEEF5';
				// this.parent.$refs.MyShowRightProject.input_search='';
				// if(this.parent.$refs.MyShowRightProject.parent_search){
				// 	this.request_current_resource_list(null, null, null, true,true);
				// 	this.parent.$refs.MyShowRightProject.parent_search=false;
				// }
				console.log(data)
				if (
					this.current_select_level1_item != null &&
					this.current_select_level1_item.id != data.id &&
					data.level == 1 &&
					data.sequence == 1
				) {
					// this.parent.$refs.MyShowRightProject.reset();
				}
				this.current_select_level1_item = data;
				this.current_select_level2_item = null;
				if (data.level == 1 && data.sequence == 1) {
					this.parent.set_current_select_sequence(1);
					this.$router.push({
						query: {
							level_0: 3
						}
					});
				} else if (data.level == 1 && data.sequence == 2) {
					this.parent.set_current_select_sequence(2);
					this.$router.push({
						query: {
							level_0: 3
						}
					});
				} else if (data.level == 1 && data.sequence == 3) {
					this.parent.set_current_select_sequence(3);
					this.$router.push({
						query: {
							level_0: 3
						}
					});
				} else if (data.level == 1 && data.sequence == 4) {
					this.parent.set_current_select_sequence(5);
					this.$router.push({
						query: {
							level_0: 3
						}
					});
				} else if (data.level == 1 && data.sequence == 5) {
					this.parent.set_current_select_sequence(6);
					this.$router.push({
						query: {
							level_0: 3
						}
					});
				}
				this.refresh_level2_list();
				this.refresh_resource_list(data);
			},
			//页面挂载时显示我的工程
			OpenByDefault(data, item) {
				if (data == null) {
					return;
				}
				this.current_select_level1_item = data;
				this.current_select_level2_item = null;
				if (data.level == 1 && data.sequence == 1) {
					if (this.parent == null) {
						this.$parent.$parent.$parent.$parent.set_current_select_sequence(1);
					} else {
						this.parent.set_current_select_sequence(1);
					}
				} else if (data.level == 1 && data.sequence == 2) {
					this.parent.set_current_select_sequence(2);
				} else if (data.level == 1 && data.sequence == 3) {
					this.parent.set_current_select_sequence(3);
				}
				this.refresh_level2_list();
				this.refresh_resource_list(data);
			},
			//点击二级菜单
			//item > 点击目录
			click_level2_item(item) {
				this.current_select_level2_item = item;
				
				if (item.id == 997) {
					console.log(item)
					
					this.parent.$refs.MyShowRightShare.is_animation=1;
					this.parent.$refs.MyShowRightShare.init();
					return;
				} else if (item.id == 998) {
					this.parent.$refs.MyShowRightShare.is_animation=2;
					this.parent.$refs.MyShowRightShare.init();
					return;
				}else if (item.id == 996) {
					
					this.parent.$refs.MyShowRightShare.is_animation=3;
					this.parent.$refs.MyShowRightShare.init();
					return;
				}
				this.refresh_resource_list(
					this.current_select_level2_item,
					true,
					null,
					null,
					null
				);
			},
			//鼠标滑过进行提示
			//text > 鼠标滑过的资源name
			MousePassing(text) {
				let strLength = 0;
				for (let indexs = 0; indexs < text.length; indexs++) {
					let ch = text.charAt(indexs);
					if (this.Utility._UtilityString.isChinese(ch)) {
						strLength += 2;
					} else {
						strLength += 1;
					}
				}
				this.tooltip_disabled = strLength < 13;
			},

			//点击添加图标
			click_add() {
				this.add_ProjectList_input = "";
				this.dialogVisible_add = true;
			},
			//点击确定按钮（添加对话框）
			dialog_btn_click_add() {
				if (
					this.add_ProjectList_input.length == 0 ||
					this.add_ProjectList_input.indexOf(" ") >= 0
				) {
					this.$message({
						message: "请填写项目名称",
						type: "warning",
					});
					return;
				}
				//获取最大的sequence
				let max_sequence = 0;
				for (let h = 0; h < this.Child_list.length; h++) {
					if (this.Child_list[h].sequence > max_sequence) {
						max_sequence = this.Child_list[h].sequence;
					}
					if (this.Child_list[h].name == this.add_ProjectList_input) {
						this.$message({
							message: "不可添加重复文件夹名称",
							type: "warning",
						});
						return;
					}
				}
				let sequence = max_sequence + 1;
				let addData = {
					name: this.add_ProjectList_input,
					parent_id: this.current_select_level1_item.id,
					level: 2,
					sequence: sequence,
					parent_node_id: this.current_select_level1_item.node_id,
				};
				//添加请求
				let _this = this;
				requestdirectory_add(addData).then((res) => {
					_this.request_level2_list();
					this.dialogVisible_add = false;
				});
			},
			//点击删除图标
			click_delete: function(id, node_id, item) {
				this.$confirm("此操作将删除此文件夹", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						let deleteID = 1;
						if (this.get_level_one_selected() == 2) {
							deleteID = 2;
						}
						let data = {
							type: deleteID,
							node_id: node_id,
							parent_node_id: this.current_select_level1_item.node_id,
						};
						let _this = this;
						requestdirectory_delete(data).then((res) => {
							this.OpenByDefault(this.current_select_level1_item);
							_this.request_level2_list();
							_this.request_resource_list(item);
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						});
					});
			},
			//点击修改图标
			click_modify: function(id) {
				for (let i = 0; i < this.Child_list.length; i++) {
					if (this.Child_list[i].id == id) {
						this.modify_input = this.Child_list[i].name;
						this.modify_item_data = this.Child_list[i];
						break;
					}
				}
				this.dialogVisible_modify = true;
			},
			//点击确定按钮（修改名称对话框）
			dialog_btn_click_modify() {
				if (this.modify_input == "" || this.modify_input.indexOf(" ") >= 0) {
					this.$message({
						message: "请填写项目名称",
						type: "warning",
					});
					return;
				}
				for (let h = 0; h < this.Child_list.length; h++) {
					if (this.Child_list[h].name == this.modify_input) {
						this.$message({
							message: "不可修改重复文件夹名称",
							type: "warning",
						});
						return;
					}
				}
				let _this = this;
				let endData = {
					id: this.modify_item_data.id,
					name: this.modify_input,
				};
				// data={"token":"","id":,"name":""}
				requestdirectory_modify(endData).then((res) => {
					_this.request_level2_list();
					_this.dialogVisible_modify = false;
				});
				_this.dialogVisible_modify = false;
			},
		},
	};
</script>
<style scoped>
	.main {
		width: 277px;
		height: 834px;
		margin-right: 12px;
		background-color: #fff;
		padding-top: 0px;
    box-shadow:0px 0px 5px #b1b1b1;
    border-radius: 8px;
    overflow: hidden;
	}

	.fr {
		float: right;
		margin-right: 10px;
	}

	.fl {
		float: left;

		max-width: 150px;

		overflow: hidden;

		text-overflow: ellipsis;

		white-space: nowrap;
	}

	.active_list {
		color:rgb(196, 62, 27);
	}

	.sanjiao {
		color: #999;
	}

	.textov {
		white-space: nowrap;
		/*强制一行内显示*/
		overflow: hidden;
		/*溢出隐藏*/
		text-overflow: ellipsis;
		/*超出部分现实省略号*/
	}

	.active_list {
		color: rgb(196, 62, 27)f;
	}

	.nav_list {
		font-size: 14px;
		font-weight: bold;
		border: 1px solid #eeeeee;
		padding: 27px 24px 22px 56px;
	}

	.mall_nav_list {
		font-weight: 500;
		margin: 5px 0;
	}

	.el-collapse-item__header .is-active {
		border-bottom-color: #ebeef5 !important;
	}

	.el-collapse-item__wrap {
		border: none !important;
	}

	.main-center[data-v-d98525bc] {
		width: 1086px !important;
	}

	.active_title {
		color: rgb(196, 62, 27);
	}
  .myshow_let_tips_text{
    text-align: center;
    height: 27px;
    line-height: 27px;
    cursor: pointer;
    width: 100%;
  }
  .myshow_let_tips_text:hover{
    background:#c43e1b ;
    color: white;
  }
  /* /deep/ .el-popover  {
    padding: 0 !important;
    min-width: 80px !important;
  } */
  >>>.el-popover{
  	min-width: 84px;
  	padding: 0;
  }
  >>>.el-button--primary{
  	border-color: #c43e1b;
  	background-color:#c43e1b;
  }
</style>
<!-- 
 -->
