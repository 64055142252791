<template>
	<div class="main">
		<el-row class="main-center-text">
			<el-row class="main-center">
				<el-row style=" overflow: hidden;border-radius: 5px;box-shadow: 0px 0px 6px #c4c4c4; margin-bottom: 20px;" >
					<el-row :gutter="20" style="padding:10px; border-bottom:1px dashed #eeeeee; background: white;height: 146px">
						<el-row>
							<el-col :span="4">
								<p style="font-size: 14px; font-weight: bold; line-height: 40px; height: 40px; display: block; text-indent: 32px">我的收藏</p>
							</el-col>
							<el-col :span="5" :push="15">
								<p style="font-size: 14px; color: #CCCCCC; line-height: 40px; height: 40px; display: block;">（总空间{{session_total_storage/1024}}G/已用{{session_use_storage}}M)</p>
							</el-col>
						</el-row>
						<el-row style="line-height: 73px">
							<el-col :span="15">
								<el-radio-group v-model="fiter_type_radio_group" @change="fiter_type_radio_group_change()" style="margin-left: 32px;">
									<el-radio :label="null" size="mini">全部</el-radio>
									<el-radio :label="1" size="mini">模型</el-radio>
									<el-radio :label="2" size="mini">动画</el-radio>
									<el-radio :label="3" size="mini">脚本</el-radio>
								</el-radio-group>
							</el-col>
							<!-- 搜索框 -->
							<el-col :span="8">
								<el-input placeholder="请输入内容" v-model="input_search" class="input-with-select" @keyup.enter.native="btn_click_search()"
								 style="width:380px;height:  42px;">
								</el-input>
							</el-col>
						</el-row>
					</el-row>
				</el-row>
				<el-col class="main-right-text" :span="19">
					<el-row v-show="Notyettext">
						<div style="margin: 0 auto; width:80px; margin-top: 150px; color: #9EAAC4;">暂无资源</div>
					</el-row>
					<div class="main-right-text-img">
						<!-- 资源列表 -->
						<el-col  v-for="(goodsData1,index) in resource_list" class='hoverbox' >
						    <el-col style="" class='box' >
						        <el-col :span="24" style='height: 183px; background: #e6e6e6; overflow: hidden; position: relative;'>
									<div  class="imgbox">
										<img :src="common.retun_img(goodsData1.thumbnail)"  style="width: 80%;margin: 0 auto; display: block; " oncontextmenu="return false;"  @click="toDetail(goodsData1.id)" >
									</div>
									<!-- <div style="position: absolute; right: 6px; top: 6px; cursor: pointer;" @click="fabulous(goodsData1)">
										<p><img :src=" goodsData1.fabulous==1?imgurlhover:imgurl" alt="" style="width: 20px;"></p>
									</div> -->
						        </el-col>
						        <el-col class="listbuttom_data">
									<div :title="goodsData1.name" class="list_top_data">
									    <span style="font-size: 16px; color: #000000;" class="text_over">{{goodsData1.name}}</span> <span>{{goodsData1.author}}</span>
									</div>
									<div style="height: 41px;line-height: 41px;color: #787777;" class='xiazai'>
									    <span >{{goodsData1.goldcoin}}币</span><span>下载量：{{goodsData1.download_number}}</span>
										<div class="tran_buttom">
											<span @click='btn_click_collect(goodsData1)' style="cursor: pointer;"class='shoucang' >
												<p><img src="../../assets/img/xiazai.svg" alt="" width="24px"  class="bottom_icon"></p>  <span class="texthover">{{goodsData1.resource_type==1 ? '购买使用':'购买下载'}}</span>
											</span>
											<span  @click="PurchasingResources(goodsData1)" style="cursor: pointer; border-left: 1px solid rgba(255,255,255,0.5); border-right: 1px solid rgba(255,255,255,0.5);"class='shoucang' >
												<p><img src="../../assets/img/gowuche.svg" alt="" width="24px;" class="bottom_icon"></p> <span class="texthover">购物车</span>
											</span>
											
										</div>
									</div>
								</el-col>
						    </el-col>
						</el-col>
					</div>
					<!-- 分页 -->
					<el-pagination background layout="prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					 :page-size="pagesize" :total="allpage" :current-page="current_page" style="margin-left: calc(40%);margin-top: 20px;"
					 v-show="allpage>=15"></el-pagination>
				</el-col>
			</el-row>
		</el-row>
	</div>
	<!-- <b style="font-size: 12px;"">
		<img src="../../assets/img/购物车空.png" alt width="16px" style="cursor: pointer;" />
	</b> -->
</template>
<script>
	//请求数据
	import {
		//删除单个资源 （收藏列表中）
		requestaccount_account_collect_delete,
		//删除多个资源 （收藏列表中）
		requestaccount_account_collect_delete_list,
		//加入购物车
		requestAddpush,
		requestStorage 
	} from "../../api/request.js";

	export default {
		data() {
			return {
				/**
				 * 	file_size: 7
					id: 1
					name: "测试(1)"
					resource_id: 15407
					state: 2
					thumbnail: "C2110C1720FAEAE6E9729869FACB41DB.png
				 */
				//资源数据
				resource_list: [],
				//分页使用：全部资源总条数
				allpage: 0,
				//分页使用：当前页面显示的条数
				pagesize: null,

				//选中的资源
				selected_item_list: [],
				//全选checkbox
				checkbox_all: false,
				//当前页面
				current_page: null,
				//所有资源的选中状态[{id:1,checked:false}]
				resource_check_list: [],
				//判断是否有资源
				Notyettext: null,
				session_use_storage: null,
				session_total_storage:null,
				//筛选动画/模型
				fiter_type_radio_group: null,
				//搜索框里的搜索值
				input_search: "",
			};
		},
		methods: {
			//初始化
			init: function(_parent) {
				this.parent = _parent;
			},
			//重置
			reset: function() {},
			// 添加至购物车
			PurchasingResources: function(item) {
				this.$confirm("此资源将添加至购物车, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						let _this = this;
						requestAddpush(item.id).then((res) => {
							if (res.ret == 0) {
								this.$message({
									showClose: true,
									message: "此资源已成功添加到购物车",
									type: "success",
								});
							} else if (res.code == 5012) {
								_this
									.$confirm(
										"BIM模型库中的企业CI库资源与BIM动画库中资源非会员不可购买！是否前往购买会员？",
										"提示", {
											confirmButtonText: "确定",
											cancelButtonText: "取消",
											type: "warning",
										}
									)
									.then(() => {
										_this.$router.push("/VipPrivilge");
									})
									.catch(() => {
										_this.$message({
											type: "info",
											message: "已取消前往购买",
										});
									});
							}
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消",
						});
					});
			},
			//设置数据
			refresh_data_collect: function(data, per_page, current_page, total) {
				if (data.data.length == 0) {
					this.Notyettext = true;
				} else {
					this.Notyettext = false;
				}
				this.resource_list = data.data;
				this.allpage = data.total;
				this.pagesize = data.per_page;
				this.current_page = data.current_page;

				this.resource_check_list = [];
				for (let index = 0; index < this.resource_list.length; index++) {
					const element = this.resource_list[index];
					this.resource_check_list.push({
						id: element.id,
						checked: false,
					});
				}
			},
			//点击搜索按钮
			btn_click_search: function() {
				this.parent_search = true;
				this.parent.$refs.MyShowLeft.request_current_resource_list(
					null,
					null,
					null,
					true,
					true,
					this.input_search
				);
			},
			//全部 模型 动画
			fiter_type_radio_group_change: function(v) {
				//刷新当前目录的数据
				this.parent.$refs.MyShowLeft.request_current_resource_list(
					this.fiter_status_radio_group,
					this.fiter_type_radio_group
				);
			},
			//点击图片跳转到详情页面
			ResourceDetails(item) {
				console.log(item)
				let id = item.id;
				this.$router.push({
					path: "/ModeResources",
					query: {
						id
					}
				});
			},
			btn_click_collect(item){
				let id = item.id;
				this.$router.push({
					path: "/ModeResources",
					query: {
						id
					}
				});
				
				
				
			},
			//删除单个资源
			click_delete_item(item) {
				let _this = this;
				this.$confirm("此操作将取消收藏该资源，是否继续", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						let data = {
							id: item.id,
						};
						requestaccount_account_collect_delete(data).then((res) => {
							//刷新当前目录的数据
							_this.parent.$refs.MyShowLeft.request_current_resource_list(
								null,
								null,
								null,
								true
							);
						});
						this.$message({
							type: "success",
							message: "删除成功!",
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						});
					});
			},
			//批量删除
			delete_batch: function() {
				let _this = this;
				this.$confirm("此操作将取消收藏该资源，是否继续", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						if (_this.resource_check_list.length <= 0) {
							this.$message({
								message: "暂无文件可删除",
								type: "warning",
							});
							return;
						}
						let datas = [];
						for (let index = 0; index < this.$refs.check.length; index++) {
							if (this.$refs.check[index].checked) {
								datas.push({
									id: parseInt(this.$refs.check[index].name),
								});
							}
						}
						let data = {
							value_array: datas,
						};
						requestaccount_account_collect_delete_list(data).then((res) => {
							_this.parent.$refs.MyShowLeft.request_current_resource_list(
								null,
								null,
								null,
								true
							);
							this.$message({
								type: "success",
								message: "删除成功!",
							});
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						});
					});
			},

			//页数改变
			handleSizeChange: function() {},
			// 分页处理函数
			handleCurrentChange: function(val) {
				window.scrollTo(0, 0);
				this.parent.$refs.MyShowLeft.request_current_resource_list(
					null,
					null,
					val,
					true
				);
			},
		},
		watch: {
			//全选/全不选
			checkbox_all: function() {
				for (let index = 0; index < this.$refs.check.length; index++) {
					this.$refs.check[index].checked = this.checkbox_all;
				}
			},
		},
		mounted() {
			
			if(JSON.parse(localStorage.getItem('storage'))==null){
				let data = {};
				requestStorage(data).then((res) => {
					console.log(res);
					localStorage.setItem('storage',JSON.stringify(res.ret))
					this.session_total_storage = res.ret.total_storage;
					this.session_use_storage = res.ret.use_storage;
				});
			}else{
				this.session_total_storage = JSON.parse(localStorage.getItem('storage')).total_storage;
				this.session_use_storage = JSON.parse(localStorage.getItem('storage')).use_storage;
			}
		},
	};
</script>
<style scoped>
	@import "../../style/common.css";

	.main {
		padding-top: 0px;
	}

	.main-center-text {
		background: #d1e1f9;
	}

	.main-center {
		width: 1100px;
		background-color: #f8f8f8;
		justify-content: flex-start;
		margin: 0 auto;
	}

	.main-right-text {
		width: 1100px;
		/* background-color: #d1e1f9; */
		/* overflow: hidden; */
	}

	.icon {
		width: 20px;
		background: url(../../assets/img/close.png) no-repeat center center;
		display: block;
		height: 15px;
		cursor: pointer;
	}

	.icon2 {
		background: url(../../assets/img/转移.png) no-repeat center center;
	}

	.MyShowCode-navbar {
		height: 40px;
		line-height: 40px;
		background-color: #fff;
		display: flex;
		justify-content: flex-start;
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.MyShowCode-navbar {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
	}

	.MyShowCode-navbar span {
		margin-left: 13px;
		margin-right: 13px;
	}

	.MyShowCode-navbar span b {
		margin-left: 15px;
	}

	.weima {
		height: 51px;
		line-height: 51px;
		background-color: #fff;
		justify-content: flex-end;
		margin-top: 5px;
	}

	.weima span {
		color: #000000;
		font-size: 14px;
		margin-right: 10px;
		cursor: pointer;
	}

	.main-right-text-img {
		width: 1100px;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	.uploadingimg {
		width: 264px;
		background-color: #fff;
		height: ;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 10px;
		margin-right: 9.7px;
		height: 270px;
		cursor: pointer;
	}

	.uploadingimg img {
		/* transform: translat(calc(-50% + 70 px), calc(-50% + 70 px)); */
		display: block;
		width: 180px;
	}

	.uploadingimg p {
		margin-left: 103px;
		margin-top: 20px;
		font-size: 14px;
		color: #c8c8c8;
	}

	.uploadingimgtwo {
		width: 264px;
		height: 270px;
		background-color: #fff;
		margin-top: 10px;
		margin-right: 9.7px;
		color: #999;
		box-shadow: 0px 0px 6px #c4c4c4;
	}

	.uploadingimgtwo>img {
		width: 227px;
		height: 205px;
		padding: 10px 15px 0;
	}

	.uploadingimgtwo p {
		font-size: 14px;
		margin-left: 13px;
		line-height: 20px;
	}

	.uploadingimgtwo:hover {
		background-color: #c5dcff;
	}

	.up-bott-font {
		margin-left: 12px;
		display: flex;
		justify-content: space-between;
		margin-right: 13px;
	}

	.up-bott-font span {
		display: block;
		font-size: 12px;
	}

	.QRcodeclass {
		position: absolute;
		top: -210px;
		left: -160px;
		background: wheat;
		display: block;
		width: 200px;
		height: 200px;
	}

	.add_img {}
	>>>.el-radio__input.is-checked .el-radio__inner {
		background: #b6492c;
		border-color: #b6492c;
	}
	
	>>>.el-radio__input.is-checked+.el-radio__label {
		color: #b6492c;
	}
	
	
	
	
	.hoverbox{
		width: 263px;
		height: 266px;
		border-radius: 4px;
		overflow: hidden;
		margin-right: 12px;
		box-shadow: 0px 0px 6px #c4c4c4;
		margin-bottom: 30px;
	}
	.box{background: #fff;}
	.box.imgbox img{
		transform: scale(0.5);
	}
	.hoverbox:hover{
		box-shadow: 0px 0px 6px 0px rgba(196,62,27,0.75);
	}
	.hoverbox:hover .tran_buttom{
		bottom: 0px;
	}
	.box .imgbox{
		/* transform: translateY(calc(50% - 55px)); */
		display:table-cell;
		vertical-align:middle;
		text-align:center;
		height: 183px;
		overflow: hidden;
	}
	
	.xiazai{
		 justify-content: space-between; display: flex;
		 margin-left: 6px;
		 margin-right: 6px;
	}
	
	.box:hover .imgbox{
		overflow: hidden;
	}
	.box:hover .imgbox img{ transform: scale(1.2); transition: 1s;}
	.texthover{
		display: block;
		color: white;
		text-align: center;
	}
	.list_top_data{
		height: 41px;
		line-height: 41px;
		border-bottom: 1px solid #ebebeb;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		color: #787777;
		justify-content: space-between; 
		display: flex;
		margin-right: 6px;
		margin-left: 6px;
	}
	.listbuttom_data{
		position: relative;
		background-color: #f8f8f8;
		border-radius: 0 0 5px 5px;
		overflow: hidden;
	}
	.tran_buttom{
		width: 100%;
		display: flex;
		position: absolute;
		bottom: -83px;
		left: 0px;
		transition: 0.5s;
	}
	.shoucang{
		width: 50%;
		height: 83px;
		background-color:rgba(196,62,28,0.9);
		display: block;
		
	}
	.shoucang:hover{
		background-color:rgba(255,146,98,0.9);
	}
	.bottom_icon{
		margin: 15px auto 0 auto; display: block;
	}
	.text_over{
		white-space:nowrap;
		overflow:hidden;
		text-overflow:ellipsis;
	}
</style>
